import React, { useState } from "react"
import Layout from "../../layout"
import SEO from "../../seo"
import styled from "styled-components"

const FaqItem = ({ item }) => {
  const [open, setopen] = useState(false)
  return (
    <>
      <SEO title={item.title} />
      <ItemContainer onClick={() => setopen(!open)}>
        <div className="title-bar">
          <h3>{item.title}</h3>
          <h3
            className={
              open ? ` arrow fa-arrow-down` : "  fa-arrow-down open arrow"
            }
          >
            ⌃
          </h3>
        </div>

        <p
          className={open ? "open " : "accordion-content "}
          dangerouslySetInnerHTML={{ __html: item.answer }}
        />
      </ItemContainer>
    </>
  )
}

export default FaqItem

const ItemContainer = styled.div`
  padding: 15px 0;
  border-bottom: 1px solid rgba(14, 0, 72, 0.4);
  // margin-bottom: 20px;

  a {
    color: var(--pink);
    text-decoration: none;
  }
  .title-bar {
    margin-bottom: 10px;
  }
  .arrow {
    transform: rotate(180deg);
  }

  .fa-arrow-down {
    transform: rotate(0deg);
    transition: transform 0.3s linear;
  }

  .fa-arrow-down.open {
    transform: rotate(180deg);
    transition: transform 0.3s linear;
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .open {
    -webkit-transition: max-height 1s;
    -moz-transition: max-height 1s;
    -ms-transition: max-height 1s;
    -o-transition: max-height 1s;
    transition: max-height 1s;

    // If use the "height: auto", the effect not works. Is necessary some value for the CSS create a CSS animate, and we can use "max-height" with a great value for emulate this effect.
    max-height: 300px;
  }
  .accordion-content {
    -webkit-transition: max-height 0.3s;
    -moz-transition: max-height 0.3s;
    -ms-transition: max-height 0.3s;
    -o-transition: max-height 0.3s;
    transition: max-height 0.3s;

    overflow: hidden;
    max-height: 0;
  }
`
