import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import BodyWrapper from "../components/BodyWrapper"
import FaqItem from "../components/template/Faq/faqItem"
const Faq = () => {
  return (
    <Layout>
      <SEO title="FAQ" />
      <Container>
        <BodyWrapper>
          <div className="max-width">
            <h1 className="main-header">FAQ</h1>
            <h1>Shipping</h1>
            {Shipping.map(obj => {
              return <FaqItem item={obj} />
            })}
            <h1>Orders</h1>
            {Orders.map(obj => {
              return <FaqItem item={obj} />
            })}

            <h1>Product</h1>
            {Product.map(obj => {
              return <FaqItem item={obj} />
            })}
            <h1>Wholesale Orders</h1>
            {Wholesale.map(obj => {
              return <FaqItem item={obj} />
            })}
            <h1>Stores</h1>
            {Stores.map(obj => {
              return <FaqItem item={obj} />
            })}
            <h1>Everything Else</h1>
            {EverythingElse.map(obj => {
              return <FaqItem item={obj} />
            })}
          </div>
        </BodyWrapper>
      </Container>
    </Layout>
  )
}

export default Faq

const Container = styled.div`
  background: var(--beige);
  padding: 70px 0;

  .main-header {
    font-size: 3rem;
  }
  h1,
  h3,
  p {
    color: var(--darkBlue);
  }

  h1 {
    font-weight: 900;
    margin: 60px 0 30px;
    text-transform: uppercase;
  }

  .max-width {
    margin: 0 auto;
    max-width: var(--hugeInNav);

    @media (max-width: 900px) {
      max-width: var(--smallLaptopWidth);
    }

    @media (max-width: 700px) {
      max-width: var(--tabletWidth);
    }

    @media (max-width: 600px) {
      max-width: var(--phoneWidth);
    }
  }
`
const Shipping = [
  {
    title: "  I just placed an order! When will it ship?",
    answer:
      "  Woohoo! Processing times vary depending on product availability; generally your order will ship in 1-3 business days. (Note that we do not ship on weekends.) ",
  },

  {
    title:
      "How long after leaving your warehouse will my order take to arrive?",
    answer:
      "If your order includes frozen items, it will ship via FedEx Overnight or 2nd Day Air. All other orders ship via UPS Ground, originating in New York state.",
  },

  {
    title: "  What shipping service do you use?",
    answer:
      "All frozen orders ship with FedEx; all non-frozen orders ship with UPS.",
  },

  {
    title: "Where can I find my tracking number? ",
    answer:
      "A tracking number will be sent automatically via email (or text, if selected) when your order is shipped, and when it is delivered. ",
  },

  {
    title: "I didn't receive a tracking number. What now?",
    answer:
      "Usually a good dig through your Spam folder will turn up a missing tracking number! But if that doesn’t work, email us at <a href='mailto:hello@belgianboys.com'>hello@belgianboys.com</a> with your order number in the subject line, and we will happily send it over.",
  },

  {
    title:
      "My tracking number shows my order has been delivered, but I did not receive it.",
    answer:
      "Uh oh! Please contact <a href='mailto:hello@belgianboys.com'>hello@belgianboys.com</a> with your order number and tracking number and we’ll look into it. ",
  },

  {
    title: "  Do you ship to Canada? ",
    answer:
      "We’re in the process of looking for a Canadian home! Join our mailing list or follow us on social media for news on when we expand Northward.",
  },
]

const Orders = [
  {
    title: "Where can I find my order status? ",
    answer:
      "After placing your order, you will receive an order confirmation email. You can click on the button “View My Order” at any time for your current order status. ",
  },

  {
    title: "Can I cancel or edit an order? ",
    answer:
      "If the order has not yet been shipped, please email us at <a href='mailto:hello@belgianboys.com'>hello@belgianboys.com</a> with the order number in the subject line, and we will do our best!",
  },

  {
    title: "  Why did my order arrive in two separate shipments?",
    answer:
      "We have partnered with the fulfillment centers best aligned with the specific needs of our perishable products. For that reason, we ship frozen items from one location and dry items from another.",
  },
  {
    title: "My order is missing an item or is wrong. What should I do? ",
    answer:
      "We’re on the case! Please email <a href='mailto:hello@belgianboys.com'>hello@belgianboys.com</a> with your order number and the products that are missing or incorrect. ",
  },

  {
    title: "My frozen order arrived melted. Is it ok to eat? ",
    answer:
      "If your frozen bundle was unfrozen on arrival, please discard the product and contact us at <a href='mailto:hello@belgianboys.com'>hello@belgianboys.com</a> with your order number in the subject line. Have no fear, we’ll make things right! ",
  },

  {
    title: "How do I apply a discount code to my order? ",

    answer:
      "During the checkout process, click on “Add a discount code or gift card” to enter any discounts or credits. ",
  },
]

const Product = [
  {
    title: "Why are Belgian Boys products made in Europe? ",
    answer:
      "Belgian Boys was born out of nostalgia for European treats, so we make real European treats, simple as that! EU ingredient standards also make it easier to source the premium, clean ingredients that go into the snacks we love.",
  },

  {
    title: "Is it all made in Belgium? ",
    answer:
      "Nope! Our founders are Belgian, but they’ve snacked their way across Europe to find the tastiest treats from Belgium’s neighboring countries. ",
  },

  {
    title: "Are Belgian Boys products gluten-free, dairy-free or vegan? ",
    answer:
      "At the moment, many of our treats are made with traditional recipes that still rely on wheat and dairy ingredients, but our Original Belgian Waffle, Chocolate Coated Belgian Waffle and Original Crêpes are all dairy-free.  ",
  },

  {
    title: "Do Belgian Boys products contain GMOs?     ",
    answer: "All of our products are certified non-GMO.  ",
  },

  {
    title: "Are Belgian Boys products Kosher? ",
    answer: "All of our products are Kosher certified by Triangle K. ",
  },

  {
    title:
      "What does it mean “may contain” or “made in a facility” with allergens?  ",
    answer:
      "This indicates that while the product does not contain the allergen as an ingredient, it is produced in a facility where this ingredient is used. Such products may contain trace amounts of allergens that can still be harmful to those who are allergic. ",
  },
  {
    title: "Are Belgian Boys products nut-free? ",
    answer:
      "We do have several products that are nut-free: The Original Crêpes, Bite-Sized Pancakes, Original Belgian Waffles, Choco Belgian Waffles and both flavors of our Mini Cookie Stashes are all nut-free, and an icon indicating such is listed on the product pages. Other products without the nut-free indicator may not contain nuts as an ingredient, but may be produced at facilities that process nuts.",
  },
  {
    title: "Where does your chocolate come from?",
    answer:
      "The chocolate in all of our products comes from Belgian chocolate supplier Callebaut®. They make traceable chocolate, with the goal that all of their chocolate and ingredients will be 100% sustainable by 2025. You can read more about their <a target='_blank' href='https://www.barry-callebaut.com/en/group/media/news-stories/forever-chocolate-barry-callebaut-targets-100-sustainable-chocolate-2025'>Forever Chocolate initiative here.</a>",
  },
  {
    title: "How do you eat a stroopwafel? ",
    answer:
      "However it tastes best to you! But traditionally, a stroopwafel is placed over a piping hot cup of coffee until the caramel melts to the perfect ooey-gooey consistency, and then enjoyed. ",
  },
]

const Wholesale = [
  {
    title: "I am an independent store. Can I buy your products?",
    answer:
      "Absolutely! We are happy to get you into our wholesaler program. Please email us at <a href='mailto:info@belgianboys.com'>info@belgianboys.com</a> for more info. ",
  },
  {
    title: "I am a buyer at a grocery chain. Do you sell to retail stores? ",
    answer:
      "Yes! We would love to chat. Please email us at <a href='mailto:info@belgianboys.com'>info@belgianboys.com</a> and we’ll have our sales team reach out. ",
  },
]

const Stores = [
  {
    title: "What stores carry Belgian Boys products? ",
    answer:
      "The best and easiest way to find stores near you is to enter an address or zip code into our store locator. You will be shown the Belgian Boys stockists nearest to you, along with the products they carry. ",
  },

  {
    title:
      "There is a store near me, but it doesn’t carry the product I want. What can I do? ",
    answer:
      "As we continue expanding our product offerings, we love to hear what products are needed (and where) from our customers. You can use our store locator to fill out a form requesting that a stockist near you carry additional Belgian Boys products, or email us at <a href='mailto:hello@belgianboys.com'>hello@belgianboys.com</a>. ",
  },
]

const EverythingElse = [
  {
    title: "Do you sell gift cards?",
    answer: "Not yet, but they’re coming soon! ",
  },
  {
    title: "I am an influencer. How can I collaborate with Belgian Boys? ",
    answer:
      "We love to work with stand-out personalities and voices in the industry! We accept influencer requests directly via Instagram DM, and proposals are reviewed on a weekly basis.  ",
  },

  {
    title: "What is #rockthatstash?",
    answer:
      "Rock that Stash is our way of giving back! You can read all about it here, but in short: To win money for the charity of your choice, just snap a pic with one of our Mini Cookie Stashes, post it to social media with the hashtag #rockthatstash, and we’ll notify you if you’ve won! ",
  },
  {
    title: "Where can I get a discount code? ",
    answer:
      "We offer periodic sales discounts to our customers; join our email list or follow us on social media to stay up-to-date on the latest promos. If you are an existing customer, you can also earn a discount on your next purchase by reviewing the products you received.     ",
  },

  {
    title: "How do I review a product? ",
    answer:
      "You will automatically receive an email inviting you to leave a review 14 days after your purchase!  ",
  },

  {
    title: "How do I sign up for your newsletter? ",
    answer: "Our newsletter signup is at the bottom of our homepage. ",
  },
]
